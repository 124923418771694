import styled from "styled-components";

export const PostWrapper = styled.div`
  //max-width: 952px;
  a {
    font-weight: 700;
    color: #6187fa;
    font-size: 18px;
    text-decoration: underline;
  }

  a:active,
  a:hover {
    outline-width: 0;
    text-decoration: underline;
  }
`;
