import styled from "styled-components";

export const PostDescription = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  border-left: 6px solid #6187fa;
  padding: 10px 16px 12px 20px;
  margin: 24px 0 48px;
  font-style: italic;
  opacity: 0.8;
  background-color: rgba(255, 255, 255, 0.05);
`;
