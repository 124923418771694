import styled from "styled-components";

export const MainContent = styled.article`
  p,
  ul,
  li {
    font-size: 20px;
    line-height: 30px;
  }

  p {
    margin: 40px 0;

    a {
      font-size: inherit;
    }
  }

  strong {
    font-weight: 700;
  }

  .gatsby-resp-image-wrapper {
    width: 100%;
    display: unset !important;
    max-height: 550px !important;

    a {
      width: 100%;
      max-height: 550px !important;
    }
  }

  img {
    width: 100%;
    max-height: 550px !important;
    object-fit: contain;
    box-shadow: none !important;
  }

  h6 {
    margin: -32px 0 40px;
    font-size: 18px;
    opacity: 0.7;
    font-weight: 400;
  }
`;
